import { NavLink, useNavigate } from 'react-router-dom';
import '../Admin/Admin.css';

export default function SideBar(){

    const navigate = useNavigate()
    const onLogout=()=>{
        localStorage.removeItem('user')
        navigate('/')
    }

    return(
        <>
            <div className="sidebar">                
                <NavLink to='records'><i className="fa-solid fa-file-contract"></i> Records</NavLink>
                <button onClick={onLogout}>Logout</button>
            </div>
        </>
    )
}
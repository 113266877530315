import { useEffect, useLayoutEffect, useState } from 'react'
import './Admin.css'
import { useForm } from 'react-hook-form'
import Loading from '../Loading';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function Agents(){

    const [addAgent,setAddAgent]=useState(true)
    const [viewAgent,setViewAgent]=useState(false)
    const [action,setAction]=useState("add")
    const [editObj,setEditObj]=useState({})
    

    const showAddAgent=()=>{
        setAction("add")
        setAddAgent(addAgent=>true)
        setViewAgent(viewAgent=>false)
    }
    
    const showViewAgent=()=>{
        setAction("add")
        setAddAgent(addAgent=>false)
        setViewAgent(viewAgent=>true)
    }
    

    const AddAgent=(action)=>{
        const {register,handleSubmit,formState:{errors},resetField,setValue,setFocus}=useForm()
        const [loadingAction,setLoadingAction]=useState("")
        const [isLoading,setLoading]=useState(false)
        const addAgent=(data)=>{
            if(action.add==="add"){
                setLoadingAction("Adding Agent..")
                setLoading(true)
                setTimeout(async() => {
                    const res = await axios.post('https://leadsoft.academy/ganesh/APIS/addAgent.php',{
                        agentName:data.agentName,
                        aliasName:data.aliasName,
                        mobile:data.mobile
                    },{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                    setLoading(false)                
                    if(res.data.status === "success") {
                        toast.success("Agent added successfully !!")
                        resetAllField()
                    } else {
                        toast.error("Something went wrong !!")
                    }
                }, 200);
            } else if(action.add==="update"){
                setLoadingAction("Updating Agent..")
                setLoading(true)
                setTimeout(async() => {
                    const res = await axios.post('https://leadsoft.academy/ganesh/APIS/updateAgent.php',{
                        agentName:data.agentName,
                        aliasName:data.aliasName,
                        mobile:data.mobile,
                        Id:data.Id
                    },{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                    setLoading(false)                
                    if(res.data.status === "success") {
                        toast.success("Agent updated successfully !!")
                        resetAllField()
                        setAction("add")
                    } else {
                        toast.error("Something went wrong !!")
                    }
                }, 200);
            }
        }

        const fetchNewAgentId = async () => {
            try {
              setLoadingAction("Setting Id...");
              setLoading(true);
              
              const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getNewAgentId.php');
              
              
              if (res.data.status === "success") {
                setValue('Id', res.data.newID);  // Dynamically set the 'Id' field value
              } else {
                console.error('Error fetching new ID:', res.data.message);
              }
            } catch (error) {
              console.error('Error fetching new agent ID:', error);
            } finally {
              setLoading(false);
            }
        };
        
        useLayoutEffect(() => {                           
            if(action.add==="add"){                
                fetchNewAgentId();
            } else {
                setValue('Id',editObj.ID)
                setValue('agentName',editObj.AgentName)
                setValue('aliasName',editObj.Aliasname)
                setValue('mobile',editObj.MobileNo)
            }
        }, []);

        const resetAllField=()=>{
            resetField('Id')
            resetField('agentName')
            resetField('aliasName')
            resetField('mobile')                        
            fetchNewAgentId();   
            setAction("add")         
        }
        useEffect(()=>{
            setFocus('agentName')
        },[setFocus])
        const firstError = errors && Object.values(errors)[0]?.message;
        return(
            <>
                { isLoading &&  <Loading message={loadingAction} /> }
                <div className="title-container">
                    <h2>{action.add==="add" ? "Add Your Agents" : "Update Your Agent"}</h2>
                </div>
                <form onSubmit={handleSubmit(addAgent)} onReset={resetAllField}>
                    <div className="input-field">
                        <label>Agent Id</label>
                        <input type='number' {...register('Id',{
                            required:"Agent Id is required."
                        })}  readOnly/>
                        
                    </div>
                    <div className="input-field">
                        <label>Agent Name</label>
                        <input type='text' {...register('agentName',{
                            required:"Agent Name is required."
                        })} />
                        
                    </div>
                    <div className="input-field">
                        <label>Alias Name</label>
                        <input type='text' {...register('aliasName',{
                            required:"Agent alias name is required."
                        })}/>
                        
                    </div>
                    <div className="input-field">
                        <label>Mobile No</label>
                        <input type='text' {...register('mobile',{
                            required:"Mobile no is required."
                        })}   />
                        
                    </div>
                    {firstError && <p className='error'>{firstError}</p>}
                    <div className="button-field">
                        <button type='submit'>{action.add ==="add" ? "Add Agent" : "Update Agent"}</button>
                        <button type='reset'>Cancel</button>
                    </div>
                </form>
            </>
        )
    }

    const ViewAgent=()=>{

        const [loadingAction,setLoadingAction]=useState("")
        const [isLoading,setLoading]=useState(false)
        const [agents,setAgents]=useState([])
        
        const recordsPerPage = 12;
        const [currentPage,setCurrentPage]=useState(1)
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = agents.slice(indexOfFirstRecord, indexOfLastRecord);
        const totalPages = Math.ceil(agents.length / recordsPerPage);

        const getAgents=async()=>{
            setLoadingAction("Getting agents...")
            setLoading(true)
            const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getAgents.php')
            setLoading(false)
            if(res.data.status === "success"){
                setAgents(res.data.data)
            }
        }

        useEffect(()=>{            
            getAgents()
        },[])

        const editAgent=(editObj)=>{            
            setEditObj(editObj)
            setAddAgent(addAgent=>true)
            setViewAgent(viewAgent=>false)
            setAction("update")
        }
        const [deleteConfirmation,setDeleteConfirmation]=useState(false)
        const [deleteAgentId,setDeleteAgentId]=useState("")
        const showDialog=(agentId)=>{
            setDeleteConfirmation(true)
            setDeleteAgentId(deleteAgentId=>agentId)
        }
        const deleteAgent=(agentId)=>{
            setLoadingAction("Deleting agents...")
            setLoading(true)
            setTimeout(async() => {
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/deleteAgent.php',{
                    agentId:agentId
                },{
                    headers:{
                        'Content-Type':'appliation/json'
                    }
                })
                setLoading(false)
                if(res.data.status === "success"){
                    toast.success('Agent deleted successfully !!')
                    getAgents()
                    setDeleteConfirmation(false)
                } else {
                    console.log(res)
                    toast.error('Something went wrong !!')
                }
            }, 200);
        }

        return(
            <>
            {
                deleteConfirmation &&
                <div className="confirmation-box">
                    <div className="question">
                        <div className="title">
                            <h2>Delete Agent ?</h2>
                        </div>
                        <div className="question-data">
                            <p>Are you sure do you want to delete this agent?</p>
                        </div>
                        <div className="footer">
                            <button onClick={()=>deleteAgent(deleteAgentId)}>Yes</button>
                            <button onClick={()=>setDeleteConfirmation(deleteConfirmation=>!deleteConfirmation)}>No</button>
                        </div>  
                    </div>
                </div>
            }
                 { isLoading &&  <Loading message={loadingAction} /> }
                <div className="title-container">
                    <h2>View Your Agents</h2>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Agent Name</th>
                            <th>Alias Name</th>
                            <th>Mobile No</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.length > 0 ? (                                
                                currentRecords.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.ID}</td>
                                            <td>{item.AgentName}</td>
                                            <td>{item.Aliasname}</td>
                                            <td>{item.MobileNo}</td>
                                            <td className='action-buttons'>
                                                <button onClick={()=>editAgent(item)}><i className="fa-solid fa-user-pen"></i></button>
                                                <button onClick={()=>showDialog(item.ID)}><i className="fa-solid fa-trash-can"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })                                
                            ) : (
                                <tr>
                                    <td colSpan="4">No agents found !!</td>                                    
                                </tr>   
                            )
                        }                        
                    </tbody>
                </table>
                <div className="pagination">
                {
                    Array.from({ length: totalPages }, (_, index) => 
                        index + 1 > 1 && (
                            <button 
                                className={currentPage === index + 1 ? 'selected-page' : ''} 
                                key={index + 1} 
                                onClick={() => setCurrentPage(index + 1)}
                            >
                                {index + 1}
                            </button>
                        )
                    )
                }
                </div>
            </>
        )
    }

    return(
        <>
            
            <div className="child-container">
                <div className="action-panel">
                    <button onClick={showAddAgent} 
                        className={addAgent ? 'activeBtn' : ''}
                        >{action==="add" ? "Add Agent" : "Update Agent"}</button>
                    <button onClick={showViewAgent}
                        className={viewAgent ? 'activeBtn' : ''}
                        >View Agents</button>
                </div>
                <div className="element-container">
                    { addAgent && <AddAgent add={action}/>  }

                    { viewAgent && <ViewAgent/> }
                </div>
            </div>
        </>
    )
}
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './Components/Login';
import {  Toaster } from 'react-hot-toast';
import Dashboard from './Components/Admin/Dashboard';
import Agents from './Components/Admin/Agents';
import User from './Components/Admin/User';
import Records from './Components/Admin/Records';
import Cash from './Components/Admin/Cash';
import UserDashboard from './Components/User/Dashboard';
import AgentRecords from './Components/Admin/AgentRecords';
import Page from './Components/Admin/Page';
import Accounts from './Components/Admin/Accounts';
import ViewAccounts from './Components/Admin/ViewAccounts';

function App() {
  return (
    <>
      <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
              background: '#363636',
              color: '#fff',
              },

              // Default options for specific types
              success: {
              duration: 3000,
              theme: {
                  primary: 'green',
                  secondary: 'black',
              },
              },
            }}
        />
      <Routes>
          <Route path='/' Component={Login}/>
          <Route path='/dashboard' Component={Dashboard}>
              <Route index Component={Page}/>
              <Route path='agents' Component={Agents}/>
              <Route path='users' Component={User}/>
              <Route path='records' Component={Records}/>
              <Route path='cash' Component={Cash}/>
              <Route path='accounts' Component={Accounts}/>
          </Route>
          <Route path='/user-dashboard' Component={UserDashboard}>
              <Route path='records' Component={Records}/>
          </Route>
          <Route path='/view-statement/:ledgerName' Component={ViewAccounts}/>
          <Route path='/agentRecords/:agentName' Component={AgentRecords}/>
      </Routes>
    </>
  );
}

export default App;

import { useForm } from 'react-hook-form';
import './Admin.css';
import { useLayoutEffect, useState } from 'react';
import Loading from '../Loading';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function Ledgers({onClose}){

    const {register,handleSubmit,formState:{errors},setFocus}=useForm()
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")

    useLayoutEffect(()=>{
        setFocus('ledger')
    },[])

    const onLedgerSubmit=(data)=>{
        setLoadingAction("Adding ledgers...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.post('https://ganesh.leadsoft.academy/APIS/addLedgers.php',data,{
                headers:{
                    'Content-Type':'application/json'
                }
            })
            const resData = res.data
            if(resData.status){
                toast.success('Ledger added successfully !!')
                onClose()
            } else {
                toast.error('Something went wrong !!')
            }
        }, 200);
    }

    return(
        <>
            {
                isLoading &&
                <Loading message={loadingAction}/>
            }
            <div className="page-container">
                <form onSubmit={handleSubmit(onLedgerSubmit)}>
                    <div className="title-form">
                        <h2>Add account ledgers</h2>
                    </div>
                    <div className="input-field">
                        <label>Ledger</label>
                        <input type='text' {...register('ledger',{
                            required:"Account ledger is required."
                        })} />
                    </div>
                    { errors.ledger && <p className='error-here'>{errors.ledger.message}</p> }
                    <div className="button-field">
                        <button type='submit'>Add Ledger</button>
                        <button onClick={onClose} type='reset'>Close</button>
                    </div>
                </form>
            </div>
        </>
    )
}
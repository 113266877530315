import { useEffect, useLayoutEffect, useState } from 'react';
import './Admin.css';
import { useForm } from 'react-hook-form';
import Loading from '../Loading';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function User(){

    const [addUser,setAddUser]=useState(true)
    const [viewUser,setViewUser]=useState(false)
    const [action,setAction]=useState("add")
    
    const [editUserObj,setEditUserObj]=useState({})

    const showAddUser=()=>{
        setAction("add")
        setAddUser(addUser=>true)
        setViewUser(viewUser=>false)
    }
    
    const showViewUser=()=>{
        setAction("add")
        setAddUser(addUser=>false)
        setViewUser(viewUser=>true)
    }



    const AddUser=(action)=>{

        const {register,handleSubmit,formState:{errors},resetField,setValue,setFocus}=useForm()
        const [loadingAction,setLoadingAction]=useState("")
        const [isLoading,setLoading]=useState(false)
        const addUser=(data)=>{
            console.log(data)
            if(action.add==="add"){
                setLoadingAction("Adding user..")
                setLoading(true)
                setTimeout(async() => {
                    const res = await axios.post('https://leadsoft.academy/ganesh/APIS/addUser.php',{
                        fullName:data.fullName,
                        machineNumber:data.machineNumber,
                        username:data.username,
                        password:data.password
                    },{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                    setLoading(false)
                    if(res.data.status === "success") {
                        toast.success("User added successfully !!")
                        resetField('Id')
                        resetField('fullName')
                        resetField('machineNumber')
                        resetField('username')
                        resetField('password')
                        fetchNewUserId()
                    } else {
                        toast.error("Something went wrong !!")
                    }
                }, 200);
            }
            else if(action.add==="update"){
                setLoadingAction("Updating user..")
                setLoading(true)
                setTimeout(async() => {
                    const res = await axios.post('https://leadsoft.academy/ganesh/APIS/updateUser.php',{
                        fullName:data.fullName,
                        Id:data.Id,
                        machineNumber:data.machineNumber,
                        username:data.username,
                        password:data.password
                    },{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                    console.log(res)
                    setLoading(false)
                    if(res.data.status === "success") {
                        toast.success("User updated successfully !!")
                        resetField('Id')
                        resetField('fullName')
                        resetField('machineNumber')
                        resetField('username')
                        resetField('password')
                        setAction("add")
                        fetchNewUserId()
                    } else {
                        toast.error("Something went wrong !!")
                    }
                }, 200);
            }
        }
        const fetchNewUserId = async () => {
            try {
              setLoadingAction("Setting User Id...");
              setLoading(true);
              
              const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getNewUserId.php');
              
              if (res.data.status === "success") {
                setValue('Id', res.data.newID);  

              } else {
                console.error('Error fetching new ID:', res.data.message);
              }
            } catch (error) {
              console.error('Error fetching new agent ID:', error);
            } finally {
              setLoading(false)
            }
        };
        const resetAllField=()=>{
            resetField('Id')
            resetField('fullName')
            resetField('machineNumber')
            resetField('username')
            resetField('password')
            
            
        }
        useEffect(()=>{
            setFocus('machineNumber')
        },[setFocus])
        useLayoutEffect(()=>{
            if(action.add === "add"){
                fetchNewUserId();
            } else if(editUserObj) {
                setValue('Id',editUserObj.id)
                setValue('fullName',editUserObj.fullName)
                setValue('machineNumber',editUserObj.machineNumber)
                setValue('username',editUserObj.userName)
                setValue('password',editUserObj.password)
            }
        },[])
        const firstError = errors && Object.values(errors)[0]?.message;
        return(
            <>
                 { isLoading &&  <Loading message={loadingAction} /> }
                <div className="title-container">
                    <h2>{action.add==="add" ? "Add Your Users" : "Update Your User"}</h2>
                </div>
                <form onSubmit={handleSubmit(addUser)} onReset={resetAllField}>
                    <div className="input-field">
                        <label>User Id</label>
                        <input type='number' {...register('Id',{
                            required:"User Id is required."
                        })}  readOnly/>                        
                    </div>
                    <div className="input-field">
                        <label>Machine No</label>
                        <input type='number' {...register('machineNumber',{
                            required:"Machine no is required."
                        })} />
                        
                        
                    </div>
                    <div className="input-field">
                        <label>Full Name</label>
                        <input type='text' {...register('fullName',{
                            required:"Full Name is required."
                        })} />
                        
                    </div>
                    <div className="input-field">
                        <label>Username</label>
                        <input type='email' {...register('username',{
                            required:"Username is required."
                        })} readOnly={action.add==="update"} />
                        
                    </div>
                    <div className="input-field">
                        <label>Password</label>
                        <input type='text' {...register('password',{
                            required:"Login password is required."
                        })}   />
                        
                    </div>
                    {firstError && <p className='error'>{firstError}</p>}

                    <div className="button-field">
                        <button type='submit'>{action.add ==="add" ? "Add User" : "Update User"}</button>
                        <button type='reset'>Cancel</button>
                    </div>
                </form>
            </>
        )
    }

    const ViewUser=()=>{

        const [loadingAction,setLoadingAction]=useState("")
        const [isLoading,setLoading]=useState(false)
        const [users,setUsers]=useState([])

        const recordsPerPage = 12;
        const [currentPage,setCurrentPage]=useState(1)
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = users.slice(indexOfFirstRecord, indexOfLastRecord);
        const totalPages = Math.ceil(users.length / recordsPerPage);


        const deleteUser=(id,username)=>{
            setLoadingAction("Deleting user..")
            setLoading(true)
            setTimeout(async() => {
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/deleteUser.php',{
                    Id:id,
                    username:username
                },{
                    headers:{
                        'Content-Type':'application/json'
                    }
                })
                console.log(res)
                setLoading(false)
                if(res.data.status === "success") {
                    toast.success("User deleted successfully !!")
                    getAgents()
                } else {
                    toast.error("Something went wrong !!")
                }
            }, 200);
        }
        const getAgents=async()=>{
            setLoadingAction("Getting users...")
            setLoading(true)
            const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getUsers.php')
            setLoading(false)
            if(res.data.status === "success"){
                setUsers(res.data.data)
            } else {
                setUsers([])
            }
        }
        useLayoutEffect(()=>{            
            getAgents()
        },[])
        const editAgent=(id,fullName,machineNumber,userName,password)=>{            
            setAddUser(addUser=>true)
            setViewUser(viewUser=>false)
            setEditUserObj({
                ...editUserObj,
                id:id,
                fullName:fullName,
                machineNumber:machineNumber,
                userName:userName,password:password
            })
            setAction("update")
        }

        return(
            <>
                 { isLoading &&  <Loading message={loadingAction} /> }
                <div className="title-container">
                    <h2>View Your Agents</h2>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Fulname</th>
                            <th>Machine No</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.length > 0 ? (                                
                                currentRecords.map((item,index)=>{
                                    return(
                                        
                                        <tr key={index}>
                                            <td>{item.ID}</td>
                                            <td>{item.Fullname}</td>
                                            <td>{item.MachineNo}</td>
                                            <td>{item.Username}</td>
                                            <td>{item.Password}         </td>
                                            <td className='action-buttons'>
                                                <button onClick={()=>editAgent(item.ID,item.Fullname,item.MachineNo,item.Username,item.Password)} title='Edit User'><i className="fa-solid fa-user-pen"></i></button>
                                                <button onClick={()=>deleteUser(item.ID,item.Username)} title='Delete User'><i className="fa-solid fa-trash-can"></i></button>                                                
                                            </td>
                                            <td>
                                                <div className="action-buttons1">
                                                    <button title='Change Password'><i className="fa-solid fa-lock-open"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })                                
                            ) : (
                                <tr>
                                    <td colSpan="5">No Users Available</td>
                                    
                                </tr>   
                            )
                        }                        
                    </tbody>
                </table>
                <div className="pagination">
                {
                    Array.from({ length: totalPages }, (_, index) => 
                        index + 1 > 1 && (
                            <button 
                                className={currentPage === index + 1 ? 'selected-page' : ''} 
                                key={index + 1} 
                                onClick={() => setCurrentPage(index + 1)}
                            >
                                {index + 1}
                            </button>
                        )
                    )
                }
                </div>
            </>
        )
    }

    return(
        <>
            <div className="child-container">
                <div className="action-panel">
                    <button onClick={showAddUser} 
                        className={addUser ? 'activeBtn' : ''}
                        >{action ==="add" ? "Add Users" : "Update User"}</button>
                    <button onClick={showViewUser}
                        className={viewUser ? 'activeBtn' : ''}
                        >View Users</button>
                </div>
                <div className="element-container">
                    { addUser && <AddUser add={action}/>  }

                    { viewUser && <ViewUser/> }
                </div>
            </div>
        </>
    )
}
import { useLayoutEffect, useRef, useState } from 'react';
import './Admin.css';
import icon from '../../rupee-indian.png';
import { useForm } from 'react-hook-form';
import Loading from '../Loading';
import axios from 'axios';
import toast from 'react-hot-toast';
import credit1 from '../../credit12.png';
import wallet from '../../wallet.png';
import debit1 from '../../debit12.png';
import add from '../../add.png';
import { NavLink } from 'react-router-dom';

export default function Records(){

    const [addRecord,setAddRecord] = useState(true)
    const [viewRecord,setViewRecord] = useState(false)
    const [viewCustomer,setViewCustomer] = useState(false)
    const [action,setAction]=useState("add")
    const [editRecord,setEditRecord]=useState({})
    const [userName,setUsername]=useState("")

    const getUsername=async()=>{
        let username = JSON.parse(localStorage.getItem('user')).username
        let userType = JSON.parse(localStorage.getItem('user')).userType
        if(userType==="Admin"){
            setUsername("Admin")
        } else {
            const res = await axios.post('https://leadsoft.academy/ganesh/APIS/getUserName.php',{
                username:username
            })
            if(res.data.status === "success"){
                setUsername(res.data.fullname)
            }
        }
        
    }

    const showAddRecord=()=>{
        setAction("add")
        setViewRecord(false)
        setViewCustomer(false)
        setAddRecord(true)
    }
    const showViewRecord=()=>{
        setAction("add")
        setViewRecord(true)
        setAddRecord(false)
        setViewCustomer(false)
    }
    const showViewCustomer=()=>{
        setViewRecord(false)
        setAddRecord(false)
        setViewCustomer(true)
    }

    const AddRecord=(action)=>{

        let today = new Date().toISOString().split('T')[0];
        const getToday=()=>{
            let today = new Date();
            let formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
            return formattedDate
        }
        let zero = 0;
        const {register,handleSubmit,formState:{errors},setFocus,setValue,reset,watch}=useForm({
            defaultValues:{
                recordDate:action.add==="add" ? today : editRecord.recordDate,
                debitAmt:zero.toFixed(2),
                creditAmt:zero.toFixed(2),
                agentName:"",
            }
        })
        const agentNameValue = watch('agentName')
        const [totalDebit,setTotalDebit]=useState(0)
        const [totalCredit,setTotalCredit]=useState(0)
        const [isLoading,setLoading]=useState(false)
        const [loadingAction,setLoadingAction]=useState("")
        const [agents,setAgents]=useState([])
        const [records,setRecords]=useState([])
        

        const handleDebitChange = (e) => {
            let value = parseFloat(e.target.value);
            if (!isNaN(value)) {
            setValue('debitAmt', value.toFixed(2));
            }
        };
        const handleCreditChange = (e) => {
            let value = parseFloat(e.target.value);
            if (!isNaN(value)) {
            setValue('creditAmt', value.toFixed(2));
            }
        };

        const [count,setCount]=useState(0)
        const [serialNo,setSerialNo]=useState(0)
        const [confirmation,setConfirmation]=useState(false)
        const [confirmationMessage,setConfirmationMessage]=useState("")
        const [updateAccountObj,setUpdateAccountObj]=useState({})
        useLayoutEffect(() => {   
            const fetchNewAgentId = async () => {
                try {
                  setLoadingAction("Setting Id...");
                  setLoading(true);
                  
                  const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getNewRecordId.php');

                  if (res.data.status === "success") {
                    setSerialNo(res.data.newID);  // Dynamically set the 'Id' field value
                  } else {
                    console.error('Error fetching new ID:', res.data.message);
                  }
                } catch (error) {
                  console.error('Error fetching new agent ID:', error);
                } finally {
                  setLoading(false);
                }
              };
            const getAgents=async()=>{
                setLoadingAction("Getting agents...")
                setLoading(true)
                const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getAgents.php')
                setLoading(false)
                if(res.data.status === "success"){
                    setAgents(res.data.data)
                }
            }
            const getRecords=async()=>{
                setLoadingAction("Getting agents...")
                setLoading(true)
                const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getCustomer.php')
                setLoading(false)
                if(res.data.status === "success"){
                    setRecords(res.data.data)
                }
            }
            const getBalance=async()=>{
                setLoadingAction("Getting balance...")
                setLoading(true)
                const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getBalance.php')
                setLoading(false)                
                if(res.data.status === "success"){
                    setTotalCredit(Number(res.data.data.totalCredit))
                    setTotalDebit(Number(res.data.data.totalDebit))
                }
            }
            
            
            const getAccountEntry=async(entry)=>{
                const res = await axios.get(`https://ganesh.leadsoft.academy/APIS/getAccountEntry.php?particular=${entry}`)
                const resData = res.data
                if(resData.status){
                    setUpdateAccountObj(resData.data[0])
                }
            }
            setFocus('agentName')
            getUsername()
            getAgents()
            getRecords()
            getBalance()
            if(action.add==="update"){
                setValue('agentName',editRecord.agentName)
                setSerialNo(editRecord.dayId)
                setValue('debitAmt',editRecord.debitAmt)
                setValue('creditAmt',editRecord.creditAmt)
                setValue('recordDate',editRecord.recordDate)
                setValue('customerName',editRecord.customerName)
                setValue('mobile',editRecord.mobile)
                let entry=`Record/${today}/${editRecord.dayId}`
                getAccountEntry(entry)
            } else {
                
                fetchNewAgentId();
                
            }
        }, [setValue,count]);
        
        
        const [recordData,setRecordData]=useState({})
        const getNumberInRupees = (number) => {
            let formattedNumber = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(Math.abs(number));         
            return formattedNumber;
        };
        const getNumberInRupees1 = (number) => {
            let formattedNumber = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(Math.abs(number)); // Format the absolute value for consistency
        
            return (number >= 0 ? '+' : '-') + formattedNumber;
        };
        
        const getCurrentTime=()=>{
            const now = new Date();
           
            let hours = now.getHours();
            const minutes = String(now.getMinutes()).padStart(2, "0");
            const ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12 || 12;
            const formattedDateTime = `${hours}:${minutes} ${ampm}`;
            return formattedDateTime
        }
        const addRecordSubmit=(data)=>{
            setRecordData(data)
            if(fileSelected===true && fileURL===""){
                toast.error('Upload file !!')
                return;
            }
            if(data.debitAmt==="0.00" && data.creditAmt==="0.00"){
               toast.error("Debit or Credit amount is required.")
               if(data.debitAmt==="0.00") setFocus('debitAmt')
               else setFocus('creditAmt')
            } else if(data.creditAmt==="0.00"){
                setConfirmationMessage("Credit amount is 0.00")
                setConfirmation(true)
            } else if(data.debitAmt==="0.00"){
                setConfirmationMessage("Debit amount is 0.00")
                setConfirmation(true)
            }
            else {
               if(action.add==="add")   addToDatabase(data)
               else updateToDatabase(data)
            }            
        }
        const confirmed=()=>{
            setConfirmation(false)
            if(action.add==="add")   addToDatabase(recordData)
            else updateToDatabase(recordData)
            
        }
        const addToDatabase=async(data)=>{
            setLoadingAction("Creating record..")
            setLoading(true)
            setTimeout(async() => {
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/addRecord.php',{
                    agentName:data.agentName,
                    creditAmt:data.creditAmt,
                    debitAmt:data.debitAmt,
                    customerName:data.customerName,
                    recordDate:data.recordDate,
                    mobile:data.mobile,
                    createdBy:userName,
                    createdAt:getCurrentTime(),
                    dayId:serialNo,
                    fileURL:fileURL
                },{
                    headers:{
                        'Content-Type':'application/json'
                    }
                })
                if(Number(data.debitAmt) > 0 && Number(data.creditAmt) > 0)
                {
                    let balance=0
                    const res2 = await axios.get('https://leadsoft.academy/ganesh/APIS/getAccountBalance.php')
                    const balanceRes = res2.data
                    
                    if(balanceRes.status==='success'){
                        balance=Number(balanceRes.data)
                    }
                    balance = balance + Number(data.creditAmt) - Number(data.debitAmt)
                    
                    const res1 = await axios.post('https://leadsoft.academy/ganesh/APIS/addOpeningBalance.php',{
                        particular:`Record/${today}/${serialNo}`,
                        debitAmt:data.debitAmt,
                        creditAmt:data.creditAmt,
                        balance:balance
                    },{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                }
                setLoading(false)
                if(res.data.status === "success") {
                    toast.success("Record Created successfully !!")
                    reset()
                    setCount(count=>count+1)
                } else {
                    toast.error("Something went wrong !!")
                }
            }, 200);
        }
        const updateToDatabase=async(data)=>{
            setLoadingAction("Updating record..")
            setLoading(true)
            setTimeout(async() => {                
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/updateRecord.php',{
                    agentName:data.agentName,
                    id:editRecord.id,
                    creditAmt:data.creditAmt,
                    debitAmt:data.debitAmt,
                    customerName:data.customerName,
                    recordDate:data.recordDate,
                    mobile:data.mobile,
                    createdBy:userName,
                    createdAt:getCurrentTime(),
                    dayId:serialNo,
                    fileURL:fileURL
                },{
                    headers:{
                        'Content-Type':'application/json'
                    }
                })
                if(Number(data.debitAmt) > 0 && Number(data.creditAmt) > 0)
                {
                    let balance=0
                    const res2 = await axios.get('https://leadsoft.academy/ganesh/APIS/getAccountBalance.php')
                    const balanceRes = res2.data
                    if(balanceRes.status==='success'){
                        balance=Number(balanceRes.data)
                    }
                    let status="";
                    if(Number(updateAccountObj.CreditAmt) === Number(data.creditAmt)){
                        status="debit"
                    } else {
                        status="credit"
                    }
                    if(status==='debit'){
                        balance = balance - (Math.abs(Number(updateAccountObj.DebitAmt)-Number(data.debitAmt)))
                    } else if(status==='credit') {
                        balance = balance + (Math.abs(Number(updateAccountObj.DebitAmt)-Number(data.debitAmt)))
                    } else {
                        balance = balance + Number(data.creditAmt) - Number(data.debitAmt)
                    }
                    const res1 = await axios.post('https://leadsoft.academy/ganesh/APIS/addOpeningBalance.php',{
                        particular:`Record/${today}/${serialNo}`,
                        debitAmt:data.debitAmt,
                        creditAmt:data.creditAmt,
                        balance:balance
                    },{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                }
                setLoading(false)
                if(res.data.status === "success" ) {
                    toast.success("Record Updated successfully !!")
                    reset()
                    setCount(count=>count+1)
                    showAddRecord()
                } else {
                    toast.error("Something went wrong !!")
                }
            }, 200);
        }
        useLayoutEffect(()=>{
            if(agentNameValue==="") return;
            setTimeout(async() => {
                setLoadingAction("Getting agent balance...")
                setLoading(true)
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/getAgentBalance.php',{
                    agentName:agentNameValue
                },{
                    headers:{
                        'Content-Type':'application/json'
                    }
                })
                setLoading(false)                
                if(res.data.success ){
                    setTotalCredit(Number(res.data.data.totalCredit))
                    setTotalDebit(Number(res.data.data.totalDebit))
                } else {
                    setTotalCredit(Number("0"))
                    setTotalDebit(Number("0"))
                }
            }, 200);
        },[agentNameValue])
        const firstError = errors && Object.values(errors)[0]?.message;
        const handleUpload = async (file) => {
            const formData = new FormData();
            formData.append("fileInput", file);
            setLoadingAction("Uploading receipt...")
            setLoading(true)
            setTimeout(async() => {
                    try {
                        const response = await fetch("https://leadsoft.academy/ganesh/APIS/uploadFile.php", {
                        method: "POST",
                        body: formData,
                        });
                        const result = await response.json();
                        setLoading(false)
                        if(result.status==='success'){
                            toast.success('Receipt uploaded successfully !!')
                            setFileURL( fileURL=> result.filePath)
                        } else {
                            toast.error('Uploading failed !!')
                        }
                    } 
                    catch (error) {
                        toast.error('Something went wrong !!')
                        console.error("Error uploading file:", error);
                    }
            }, 300);
        }
        const [fileSelected,setFileSelected]=useState(false)
        const [selectedFile,setSelectedFile]=useState(null)
        const [fileURL,setFileURL]=useState("")
        const handleFileChange = (event) => {
            const file = event.target.files[0];
            setSelectedFile(selectedFile=>file)
            setFileSelected(!!file);
        };
        const [agentName,setAgentName]=useState("")
        useLayoutEffect(()=>{
            if(agentNameValue==="") return 
            setLoadingAction("Getting agent name...")
            setLoading(true)
            setTimeout(async() => {
                const res = await axios.get(`https://ganesh.leadsoft.academy/APIS/getAgentDetails.php?aliasName=${agentNameValue}`)
                const resData = res.data
                setLoading(false)
                if(resData.status==='success'){
                    setAgentName(resData.data.AgentName)
                }
            }, 100);
        },[agentNameValue])
        

        return(
            <>
            {
                isLoading && <Loading message={loadingAction} />
            }
            {
                confirmation &&
                <div className="bg-blur">
                    <div className="box">
                        <div className="title-box">
                            <h2>Confirmation</h2> 
                            <i className="fa-solid fa-question"></i>    
                        </div>
                        <div className="message-field">                        
                            <p>{confirmationMessage}</p>                        
                        </div>
                        <p>Do you want to save this record?</p>
                        <div className="action-field">
                            <button onClick={()=>confirmed()}>Yes</button>
                            <button onClick={()=>setConfirmation(false)}>No</button>
                        </div>
                    </div>
                </div>
            }
                <div className="child-container1">
                    <form onSubmit={handleSubmit(addRecordSubmit)}>
                        <div className="title">
                           <div className='title-image'>
                                <img src={add} alt='img' />
                                <h2>
                                    {action.add==="add" ? "Add Your Record" : "Update Your Record"}
                                </h2>
                           </div>
                            <span>{ action.add==="update" ? editRecord.recordDate +" / " : getToday() +" / " }<span>{serialNo}</span></span>
                            
                        </div>
                        <div className="input-field1">
                            <label>Agent Name </label>
                            <div className="field">
                                <select value={action.add==="update" ? editRecord.agentName : agentNameValue}  
                                    
                                    
                                    {...register('agentName',{
                                    required:"Agent name is required."
                                })}>
                                    <option defaultValue=''>SELECT AGENT</option>
                                    {
                                        agents.map((item,index)=>{
                                            return(
                                                <option key={index} value={item.Aliasname}>{item.Aliasname}</option>
                                            )
                                        })
                                    }
                                </select>                           
                                
                            </div>
                        </div>
                        <div className="input-field1">
                            <label>Debit Amount </label>
                            <div className="field">
                                <div className="form-field2">
                                    <img src={icon} alt='img' />
                                    <input type='number'  {...register('debitAmt',{
                                        required:"Debit amount required."})} onBlur={handleDebitChange}  />                                
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="input-field1">
                            <label>Credit Amount </label>
                            <div className="field">
                                <div className="form-field2">
                                    <img src={icon} alt='img' />
                                    <input type='number' {...register('creditAmt',{
                                        required:"Credit amount is required."})} onBlur={handleCreditChange}  />
                                </div>
                                
                            </div>
                        </div>                      
                        <div className="input-field1">
                            <label>Customer Name </label>
                            <div className="field">                                    
                                <input type='text' {...register('customerName',{
                                    required:"Customer name is required."
                                })} 
                                />
                                
                            </div>
                        </div>
                        <div className="input-field1">
                            <label>Mobile No </label>
                            <div className="field">
                                <input type='number'  {...register('mobile')} />                                
                            </div>
                        </div>
                        <div className="input-field1">
                            <label>Record Receipt </label>
                            <div className="field field2">
                                <input type='file' accept=".pdf, .jpg, .jpeg, .png" 
                                    {...register('fileInput')} onChange={handleFileChange} />
                                { 
                                    fileSelected && 
                                    <button type='button' onClick={()=>handleUpload(selectedFile)}>Upload</button>
                                }
                                {
                                    fileURL && 
                                    <a target='_blank' rel="noreferrer" href={`https://leadsoft.academy/ganesh/APIS/${fileURL}`}
                                    download>View</a>
                                }
                            </div>
                        </div>
                        
                        {firstError && <p className='error'>{firstError}</p>}
                        
                        <div className="button-field">
                            <button type='submit'>{action.add==="add" ? "Add Record" : "Update Record"}</button>
                            <button type='reset'>Cancel</button>
                        </div>
                        
                        
                    </form>
                    <div className="panel-show">
                            <div className="title">
                                <div className="title-image" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                    <h2>Balance Sheet</h2>
                                    <p style={{padding:agentNameValue===""?"0px":"5px", fontSize:"14px",fontWeight:"510",background:"purple",color:"white",borderRadius:"4px"}}>
                                        {agentName}
                                    </p>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="record-field">
                                    <img className='show-icon' src={credit1} alt='img' />
                                    <p className='title-field'>Credit</p>
                                    <div className="value-field">
                                        <img src={icon} alt='img' />
                                        <p className='purple'>{getNumberInRupees(totalCredit)}</p>
                                    </div>
                                </div>
                                <div className="record-field">
                                    <img className='show-icon' src={debit1} alt='img' />
                                    <p className='title-field'>Debit</p>
                                    <div className="value-field">
                                        <img src={icon} alt='img' />
                                        <p className='red'>{getNumberInRupees(totalDebit)}</p>
                                    </div>
                                </div>
                                <div className="line">

                                </div>
                                <div className="record-field">
                                    <img className='show-icon' src={wallet} alt='img'/>
                                    <p className='title-field'>Balance</p>
                                    <div className="value-field">
                                        <img src={icon} alt='img' />
                                        <p className={(totalCredit-totalDebit) < 0 ? 'negative':'green'}>{getNumberInRupees1(totalCredit-totalDebit)}</p>
                                    </div>
                                </div>
                                {
                                    agentNameValue!=="" &&
                                    <NavLink to={`/agentRecords/${agentNameValue}`} rel="noreferrer"  target='_blank'>View Transactions</NavLink>
                                }
                            </div>
                    </div>
                </div>
            </>
        )
    }

    const ViewRecord=()=>{

        let today = new Date().toISOString().split('T')[0];
       
        const[records,setRecords]=useState([])
        const [isLoading,setLoading]=useState(false)
        const [loadingAction,setLoadingAction]=useState("")
        const [dateFrom,setDateFrom]=useState(today)
        const [dateTo,setDateTo]=useState(today)
        const [agentName,setAgentName]=useState(null)
        const [user,setUser]=useState(null)
        const [agents,setAgents]=useState([])
        const [users,setUsers]=useState([])
        const recordsPerPage = 10;
        const [currentPage,setCurrentPage]=useState(1)
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
        const totalPages = Math.ceil(records.length / recordsPerPage);
        const [totalDebit,setTotalDebit]=useState(0)
        const [totalCredit,setTotalCredit]=useState(0)
        const setValues=(data)=>{
            let debit =0, credit =0;
            data.forEach((element) => {
                debit += Number(element.DebitAmt)
                credit += Number(element.CreditAmt)
            });
            setTotalCredit(totalCredit=>credit)
            setTotalDebit(totalDebit=>debit)
        }
        const handlePrevious = () => {
            if (currentPage > 1) {
              setCurrentPage((prevPage) => prevPage - 1);
            }
        };
        
          const handleNext = () => {
            if (currentPage < totalPages) {
              setCurrentPage((prevPage) => prevPage + 1);
            }
        };
        useLayoutEffect(()=>{
            const getTodaysRecords=async()=>{
                setLoadingAction("Getting agents...")
                setLoading(true)
                let url=''
                if(userName==="Admin") url ='https://leadsoft.academy/ganesh/APIS/getTodaysRecords.php'
                else url=`https://leadsoft.academy/ganesh/APIS/getTodaysRecords.php?username=${userName}`
                const res = await axios.get(url)
                setLoading(false)
                if(res.data.success){
                    setRecords(res.data.data)
                }
            }
            const getAgents=async()=>{
                setLoadingAction("Getting agents...")
                setLoading(true)
                const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getAgents.php')
                setLoading(false)
                if(res.data.status === "success"){
                    setAgents(res.data.data)
                }
            }
            const getUsers=async()=>{
                setLoadingAction("Getting agents...")
                setLoading(true)
                const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getUsers.php')
                setLoading(false)
                if(res.data.status === "success"){
                    setUsers(res.data.data)
                    
                }
            }
            getTodaysRecords()
            getAgents()
            getUsers()
        },[])
        const filterData=async()=>{
            if (!dateFrom && !dateTo && !agentName && !user) {
                toast.error("Provide values to filter.");
                return;
            } else {
                let tempObj={}
                if(dateFrom && dateTo){
                    tempObj.dateFrom=dateFrom
                    tempObj.dateTo=dateTo
                }
                if(agentName){
                    tempObj.agentName=agentName
                } 
                if(user){
                    tempObj.user=user
                }
                setTimeout(async() => {
                    setLoadingAction("Filtering records...")
                    setLoading(true)
                    const res = await axios.post('https://leadsoft.academy/ganesh/APIS/getFilteredRecords.php',
                        JSON.stringify(tempObj),{
                        headers:{
                            'Content-Type':'application/json'
                        }
                    })
                    setLoading(false)          
                    if(res.data.success){
                        setRecords(res.data.data)                        
                    } else {
                        setRecords([])
                    }
                    setCurrentPage(1)
                }, 200);
            }
            
        }
        const getNumberInRupees=(number)=>{
            let formattedNumber = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,  // Minimum of 2 decimal places
                maximumFractionDigits: 2,  // Maximum of 2 decimal places
            }).format(number);
            return formattedNumber
        }
        useLayoutEffect(() => {
            setValues(currentRecords);
        }, [currentRecords]);
        const updateRecord=(id,date,agentName,debitAmt,creditAmt,customerName,mobile,created_at,created_by,dayId)=>{
            setEditRecord({
                ...editRecord,
                id:id,recordDate:date,agentName:agentName,debitAmt:debitAmt,creditAmt:creditAmt,
                customerName:customerName,mobile:mobile,createdAt:created_at,createdBy:created_by,dayId:dayId
            })
            
            setAction("update")
            setAddRecord(true)
            setViewRecord(false)
        }
        const formatDate = (dateString) => {
            const [year, month, day] = dateString.split("-");
            return `${day}-${month}-${year}`; 
          };
        return(
            <>
            {
                isLoading && <Loading message={loadingAction}/>
            }
            { userName === "Admin" &&
            <div className="filter-container">
                <div className="field">
                    <h2>By Date</h2>
                    <div className="filter-field">
                        <input type='date' value={dateFrom} onChange={(e)=>setDateFrom(e.target.value)} />
                        <p>To</p>
                        <input type='date' value={dateTo} onChange={(e)=>setDateTo(e.target.value)} />
                    </div>
                </div>
                <div className="field">
                    <h2>By Agent Name</h2>
                    <div className="filter-field">
                        <select value={agentName || ''} onChange={(e)=>setAgentName(e.target.value)} >
                            <option value='' selected>SELECT AGENT</option>                            
                            {
                                agents.map((item,index)=>{
                                    return(
                                        <option key={index} value={item.Aliasname}>{item.Aliasname}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="field">
                    <h2>By User</h2>
                    <div className="filter-field">
                        <select value={user || ''} onChange={(e)=>setUser(e.target.value)} >
                            <option value='' selected>SELECT USER</option>
                            <option value='Admin'>Admin</option>
                            {
                                users.map((item,index)=>{
                                    return(
                                        <option key={index} value={item.Fullname}>{item.Fullname}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>                
                <button onClick={filterData}>Show Records</button>
            </div>
            }
                <table style={{width:"80%"}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Agent Name</th>
                            <th>Debit (Rs.)</th>
                            <th>Credit (Rs.)</th>
                            <th>Customer</th>
                            <th>Timestamp</th>
                            <th>Created By</th>
                            { userName==="Admin" && <th></th> }
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.length > 0 ? (
                                currentRecords.map((item,index)=>{
                                    return(
                                        <tr key={index} className={item.CreditAmt==="0.00" ? 'credit' : ''}>
                                            <td>{item.DayId}</td>
                                            <td>{formatDate(item.Date)}</td>
                                            <td>{item.AgentName}</td>
                                            <td>{item.DebitAmt}</td>
                                            <td>{item.CreditAmt}</td>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.created_by}</td>
                                            {
                                            userName==="Admin" &&
                                            <td className='action-buttons'>
                                                <button onClick={()=>updateRecord(item.Id,item.Date,item.AgentName,item.DebitAmt,item.CreditAmt,item.CustomerName,item.MobileNo,item.created_at,item.created_by,item.DayId)} title='Update Record'><i className="fa-solid fa-clipboard"></i></button>
                                                <button title='Delete Record'><i className="fa-solid fa-trash-can"></i></button> 
                                            </td>
                                            }
                                            <td style={{background:"white"}}>
                                                {
                                                    item.Receipt !=="" && 
                                                    <a target='_blank' rel="noreferrer" href={`https://leadsoft.academy/ganesh/APIS/${item.Receipt}`}>
                                                        <i className="fa-solid fa-eye eye"></i>
                                                    </a>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan="8">No Records found</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <div className="pagination" style={{width:"80%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"blue",color:"white",fontWeight:"520"}}>Total Records :</p>
                        <p style={{padding:"4px",color:"blue",fontWeight:"bold"}}>
                            {records.length}
                        </p>
                        
                    </div>                
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"red",color:"white",fontWeight:"520"}}>Total Debit :</p>
                        <p style={{padding:"4px",color:"red",fontWeight:"bold"}}>{getNumberInRupees(totalDebit)}</p>
                    </div>
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"darkgreen",color:"white",fontWeight:"520"}}>Total Credit :</p>
                        <p style={{padding:"4px",color:"darkgreen",fontWeight:"bold"}}>
                            {getNumberInRupees(totalCredit)}</p>
                    </div>
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",color:"white",fontWeight:"520",background:(totalCredit-totalDebit)<0  ? "red" : "green"}}>
                            { (totalCredit-totalDebit) < 0 ? "CASH IN : " :"CASH OUT : " }
                        </p>
                        <img src={icon} alt='img'/>
                        <p style={{padding:"4px",color:"black",fontWeight:"bold"}}>
                            {getNumberInRupees((totalCredit-totalDebit))}</p>
                    </div>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"end",gap:"7px"}}>
                        {
                            totalPages > 1 &&
                            <>
                                <p><span>Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong></span></p>
                                <div className="data123">
                                    <button onClick={handleNext} disabled={currentPage===1}><i className="fa-solid fa-circle-left"></i></button>
                                    <button onClick={handlePrevious}disabled={currentPage===totalPages}><i className="fa-solid fa-circle-right"></i></button>
                                </div>                        
                            </>
                        }  
                    </div>
                </div>
            </>
        )
    }
    const ViewCustomer=()=>{

        const userInput = useRef(null)
        const [record,setRecord]=useState([])
        const [searchText, setSearchText] = useState('');
        const [filteredCustomers, setFilteredCustomers] = useState(record);
        const [isLoading,setLoading]=useState(false)
        const [loadingAction,setLoadingAction]=useState("")
        
        const recordsPerPage = 10;
        const [currentPage,setCurrentPage]=useState(1)
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = filteredCustomers.slice(indexOfFirstRecord, indexOfLastRecord);
        const totalPages = Math.ceil(filteredCustomers.length / recordsPerPage);

        useLayoutEffect(()=>{
            if(userInput){
                userInput.current.focus()
            }
            getRecords()
        },[])
       
        useLayoutEffect(() => {
            const results = record.filter(customer =>
                customer.CustomerName.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredCustomers(results);
        }, [searchText, record]); 
        const handlePrevious = () => {
            if (currentPage > 1) {
              setCurrentPage((currentPage) => currentPage - 1);
            }
        };
        
        const handleNext = () => {
            if (currentPage < totalPages) {
              setCurrentPage((currentPage) => currentPage + 1);
            }
        };
        const getRecords=()=>{
            setLoadingAction("Getting records...")
            setLoading(true)
            setTimeout(async() => {
                const res = await axios.get('https://ganesh.leadsoft.academy/APIS/getRecords.php')
                const resData = res.data
                setLoading(false)
                if(resData.success){
                    setRecord(resData.data)
                } else {
                    setRecord([])
                }
            }, 200);
        }
        

        return(
            <>
                {
                    isLoading &&
                    <Loading message={loadingAction}/>
                }
                
                <div className="full-field">
                    <label>Customer Name</label>
                    <input ref={userInput} value={searchText} onChange={e => setSearchText(e.target.value)} type='text'/>
                </div>
             
                <table style={{width:"80%"}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Agent Name</th>
                            <th>Debit (Rs.)</th>
                            <th>Credit (Rs.)</th>
                            <th>Customer</th>
                            <th>Timestamp</th>
                            <th>Created By</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.length > 0 ? (
                                currentRecords.map((item,index)=>{
                                    return(
                                        <tr key={index} className={item.CreditAmt==="0.00" ? 'credit' : ''}>
                                            <td>{item.DayId}</td>
                                            <td>{item.Date}</td>
                                            <td>{item.AgentName}</td>
                                            <td>{item.DebitAmt}</td>
                                            <td>{item.CreditAmt}</td>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.created_by}</td>                                            
                                            <td style={{background:"white"}}>
                                                {
                                                    item.Receipt !=="" && 
                                                    <a target='_blank' rel="noreferrer" href={`https://leadsoft.academy/ganesh/APIS/${item.Receipt}`}>
                                                        <i className="fa-solid fa-eye eye"></i>
                                                    </a>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan="7">No Records found</td>
                                </tr>
                            )
                        }                   
                    </tbody>
                </table>
                <div className="pagination" style={{width:"80%",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                {
                    totalPages > 1 &&
                    <>
                        <p><span>Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong></span></p>
                        <div className="data123">
                            <button title='Previous Page' onClick={()=>handlePrevious()} disabled={currentPage===1}><i className="fa-solid fa-circle-left"></i></button>
                            <button title='Next Page' onClick={()=>handleNext()}disabled={currentPage===totalPages}><i className="fa-solid fa-circle-right"></i></button>
                        </div>                        
                    </>
                }                      
                </div>
            </>
        )
    }
    
    return(
        <>
             <div className="child-container">
                <div className="action-panel">
                    <button onClick={showAddRecord} 
                        className={addRecord ? 'activeBtn' : ''}
                        >{action==="add" ? "Add Record" : "Update Record"}</button>
                    <button onClick={showViewRecord}
                        className={viewRecord ? 'activeBtn' : ''}
                        >View Records</button>
                    <button onClick={showViewCustomer}
                        className={viewCustomer ? 'activeBtn' : ''}
                        >
                        Search Customer
                    </button>
                </div>
                <div className="element-container">
                    { addRecord && <AddRecord add={action}/>  }

                    { viewRecord && <ViewRecord/> }

                    { viewCustomer && <ViewCustomer/> }
                </div>
            </div>
        </>
    )
}
import { useLayoutEffect, useState } from 'react';
import './Admin.css';
import Loading from '../Loading';
import axios from 'axios';

export default function Page(){

    const [accountFilter,setAccountFilter]=useState("today")
    const [recordFilter,setRecordFilter]=useState("today")
    const [accountStatements,setAccountStatements]=useState([])
    const [records,setRecords]=useState([])
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")
    const recordsPerPage = 10;
    const [currentPage,setCurrentPage]=useState(1)
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = accountStatements.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(accountStatements.length / recordsPerPage);

    const [currentPage1,setCurrentPage1]=useState(1)
    const indexOfLastRecord1 = currentPage1 * recordsPerPage;
    const indexOfFirstRecord1 = indexOfLastRecord1 - recordsPerPage;
    const currentRecords1 = records.slice(indexOfFirstRecord1, indexOfLastRecord1);
    const totalPages1 = Math.ceil(records.length / recordsPerPage);

    const getAccountStatement=(filter)=>{
        
        setLoadingAction("Getting account statement...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get(`https://ganesh.leadsoft.academy/APIS/getAccountStatement.php?filter=${filter}`)
            const resData = res.data
            setLoading(false)
            if(resData.status){
                setAccountStatements(resData.data)
            }
        }, 200);
    }
    const getRecords=(filter)=>{
        
        setLoadingAction("Getting records...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get(`https://ganesh.leadsoft.academy/APIS/getRecordDash.php?filter=${filter}`)
            const resData = res.data
            setLoading(false)
            if(resData.status){
                setRecords(resData.data)
            }
        }, 200);
    }
    useLayoutEffect(()=>{
        getAccountStatement(accountFilter)        
    },[accountFilter])
    useLayoutEffect(()=>{
        getRecords(recordFilter)
    },[recordFilter])
    const setFilterWeek=(filter)=>{
        setAccountFilter(filter)
    }
    
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`; // rearrange to dd-mm-yyyy
    };
    const handlePrevious = () => {
        if (currentPage1 > 1) {
          setCurrentPage1((prevPage) => prevPage - 1);
        }
    };
    
      const handleNext = () => {
        if (currentPage1 < totalPages1) {
          setCurrentPage1((prevPage) => prevPage + 1);
        }
    };
    const handlePrevious1 = () => {
        if (currentPage1 > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    
      const handleNext1 = () => {
        if (currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    return(
        <>
            {
                isLoading &&
                <Loading message={loadingAction}/>
            }
            <div className="page-container1">
                <div className="title">
                    <i className="fa-solid fa-gauge"></i> <h2>Your Dashboard</h2>
                </div>
                <div className="data-container">
                    <div className="account-statement">
                        <div className="title-field">
                            <h2><i className="fa-solid fa-file-invoice-dollar"></i> Account Statement</h2>
                            <div className="filter">
                                <p className={accountFilter==="today" ? 'selected-filter' : ''} onClick={()=>setAccountFilter('today')}>Today</p>
                                <p className={accountFilter==="week" ? 'selected-filter' : ''} onClick={()=>setFilterWeek('week')}>Past 7 days</p>
                                <p className={accountFilter==="custom" ? 'selected-filter' : ''} onClick={()=>setFilterWeek('custom')}>Custom Date</p>
                            </div>
                        </div>
                        
                        <table className='table-data'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>particular</th>
                                    <th>Debit(Rs.)</th>
                                    <th>Credit(Rs.)</th>
                                    <th>Balance(Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    
                                    currentRecords.map((item,index)=>{
                                        let balanceChange = "";
                                        if (index > 0) { 
                                            const previousBalance = currentRecords[index - 1].Balance; // Get the balance of the previous record
                                            if (item.Balance > previousBalance) {
                                                balanceChange = "Up"; 
                                            } else if (item.Balance < previousBalance) {
                                                balanceChange = "Down"; 
                                            } else {
                                                balanceChange = "No Change"; 
                                            }
                                        }
                                        return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.Particular}</td>
                                                <td>{item.DebitAmt}</td>
                                                <td>{item.CreditAmt}</td>
                                                <td style={balanceChange==="Up" ? {color:"green",fontWeight:"510"} : {color:"red",fontWeight:"510"}}>{item.Balance}  
                                                    {
                                                        balanceChange==="Up" &&
                                                        <i style={{fontSize:"18px",color:"green"}} className="fa-solid fa-caret-up"></i>
                                                    }
                                                    {
                                                        balanceChange==="Down" &&
                                                        <i  style={{fontSize:"18px",color:"red"}} className="fa-solid fa-caret-down"></i>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="pagination">
                        {
                            totalPages > 1 &&
                            <>
                                <p><span>Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong></span></p>
                                <div className="data123">
                                    <button title='Previous Page' onClick={()=>handlePrevious1()} disabled={currentPage===1}><i className="fa-solid fa-circle-left"></i></button>
                                    <button title='Next Page' onClick={()=>handleNext1()}disabled={currentPage===totalPages}><i className="fa-solid fa-circle-right"></i></button>
                                </div> 
                                     
                            </>
                        }   
                        </div>
                    </div>
                    <div className="account-statement">
                        <div className="title-field">
                            <h2> <i className="fa-solid fa-file-waveform"></i> Record Statement</h2>
                            <div className="filter">
                                <p className={recordFilter==="today" ? 'selected-filter' : ''} onClick={()=>setRecordFilter('today')}>Today</p>
                                <p className={recordFilter==="week" ? 'selected-filter' : ''} onClick={()=>setRecordFilter('week')}>Past 7 days</p>
                                <p className={recordFilter==="custom" ? 'selected-filter' : ''} onClick={()=>setRecordFilter('custom')}>Custom Date</p>
                            </div>
                        </div>
                        <table className='table-data'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>agent name</th>
                                    <th>Debit(Rs.)</th>
                                    <th>Credit(Rs.)</th>
                                    <th>created by</th>
                                </tr>
                            </thead>
                            <tbody>
                            {                                    
                                currentRecords1.map((item,index)=>{                                    
                                    return(
                                        <tr 
                                            key={index}
                                            style={{
                                                background: item.CreditAmt === "0.00" ? "red" : 
                                                        Number(item.DebitAmt) > Number(item.CreditAmt) ? "purple" : "transparent",
                                                color: item.CreditAmt === "0.00" ? "white" : 
                                                    Number(item.DebitAmt) > Number(item.CreditAmt) ? "white" : "black"
                                            }}
                                        >

                                        <td>{formatDate(item.Date)}</td>
                                        <td>{item.AgentName}</td>
                                        <td>{item.DebitAmt}</td>
                                        <td>{item.CreditAmt}</td>
                                        <td>{item.created_by}</td>
                                    </tr>
                                    )
                                })
                            }
                                
                            </tbody>
                        </table>
                        <div className="pagination">
                            {
                                totalPages1 > 1 &&
                                <>
                                    <p><span>Page <strong>{currentPage1}</strong> of <strong>{totalPages1}</strong></span></p>
                                    <div className="data123">
                                        <button title='Previous Page' onClick={()=>handlePrevious()} disabled={currentPage1===1}><i className="fa-solid fa-circle-left"></i></button>
                                        <button title='Next Page' onClick={()=>handleNext()}disabled={currentPage1===totalPages1}><i className="fa-solid fa-circle-right"></i></button>
                                    </div>                                                         
                                </>
                            }                            
                        </div>
                    </div>                    
                </div>    
                
            </div>

        </>
    )
}
import { NavLink, useNavigate } from 'react-router-dom';
import './Admin.css';


export default function SideBar(){
    const navigate = useNavigate()
    const onLogout=()=>{
        localStorage.removeItem('user')
        navigate('/')
    }
    return(
        <>
            <div className="sidebar">
                <NavLink to='/' end><i className="fa-solid fa-gauge"></i> Dashboard</NavLink>
                <NavLink to='accounts'><i className="fa-solid fa-file-invoice-dollar"></i> Accounts</NavLink>
                <NavLink to='agents'><i className="fa-solid fa-user-secret"></i> Agents</NavLink>
                <NavLink to='users'><i className="fa-solid fa-user-tie"></i> Users</NavLink>
                <NavLink to='records'><i className="fa-solid fa-file-contract"></i> Records</NavLink>
                <NavLink to='cash'><i className="fa-solid fa-briefcase"></i> Cash Manager</NavLink>                
                <button onClick={onLogout}>Logout</button>
            </div>
        </>
    )
}
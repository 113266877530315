import { Outlet, useNavigate } from 'react-router-dom';
import '../Admin/Admin.css';
import { useLayoutEffect } from 'react';
import SideBar from './SideBar';

export default function Dashboard(){

    const navigate = useNavigate()

    useLayoutEffect(()=>{
        const user = localStorage.getItem('user')
        if(!user){
            navigate('/')
        }
    },[])

    return(
        <>
            <SideBar/>
            <div className="outlet-container">
                <Outlet/>
            </div>
            <div className="admin-footer">
                <p>&copy; {new Date().getFullYear()} LeadSoft IT Solutions. All Rights Reserved.</p>
            </div>
        </>
    )
}
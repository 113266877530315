import './Admin.css';
import icon from '../../rupee-indian.png';
import reason from '../../reason.png';
import { useLayoutEffect, useRef, useState } from 'react';
import Loading from '../Loading';
import axios from 'axios';
import toast from 'react-hot-toast';
import cashIcon from '../../cash-remove.png';
import OpeningBalance from './OpeningBalance';
import Ledgers from './Ledgers';

export default function Cash(){

    const [amt,setAmt]=useState("0.00")
    const [reasonFor,setReason]=useState("")
    const [isError,setError]=useState(false)
    const [errorMsg,setErrorMsg]=useState("")
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")
    const [transactions,setTransactions]=useState([])
    const [openBalance,setOpenBalance]=useState(false)
    const [ledgers,setLedgers]=useState(false)
    const [ledgerRecords,setLedgerRecords]=useState([])
    const recordsPerPage = 10;
    const [currentPage,setCurrentPage]=useState(1)
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = transactions.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(transactions.length / recordsPerPage);
    const [openingBalanceStatus,setOpeningBalanceStatus]=useState("NO")
    let today = new Date().toISOString().split('T')[0];
    const [dateFrom,setDateFrom]=useState(today)
    const [dataTo,setDateTo]=useState(today)
    const [cashType,setCashType]=useState("")
    const [lastDayBalance,setLastDayBalance]=useState(0)
    const [cashDebit,setCashDebit]=useState(0)
    const [cashCredit,setCashCredit]=useState(0)
    const [accountStatements,setAccountStatements]=useState([])

    const getAccountStatement=(filter)=>{
        
        setLoadingAction("Getting account statement...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get(`https://ganesh.leadsoft.academy/APIS/getAccountStatement.php?filter=today`)
            const resData = res.data
            setLoading(false)
            if(resData.status){
                setAccountStatements(resData.data)
                let debit=0,credit=0;
                for (let index = 0; index < resData.data.length; index++) {
                    const element = resData.data[index];
                    debit += Number(element.DebitAmt)
                    credit += Number(element.CreditAmt)                    
                }
                setCashCredit(credit)
                setCashDebit(debit)
            }
        }, 200);
    }
    const getLastBalance=()=>{
        setLoadingAction("Getting Opening Balance...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get('https://ganesh.leadsoft.academy/APIS/getLastDayBalance.php')
            const resData = res.data
            setLoading(false)
            if(resData.status==='success'){
                setLastDayBalance(resData.data[0].Balance)
            }
        }, 200);
    }
    useLayoutEffect(()=>{
        getLastBalance()
        getAccountStatement()
    },[])
    const handleFromChange=(e)=>{
        setDateFrom(e.target.value)
        getTransactionsDates()
    }
    const handleToChange=(e)=>{
        setDateTo(e.target.value)
        getTransactionsDates()
    }
    const handleTypeChange=(e)=>{
        setCashType(e.target.value)
        getTransactionsType(e.target.value)
    }
    const getNumberInRupees = (number) => {
        let formattedNumber = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Math.abs(number));         
        return formattedNumber;
    };
    const getLedgers=async()=>{
        setLoadingAction("Getting ledgers...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get('https://ganesh.leadsoft.academy/APIS/getLedgers.php')
            const resData = res.data
            setLoading(false)
            if(resData.status==='success'){
                setLedgerRecords(resData.data)
            }
        }, 200);
    }

    const cashIn=()=>{
        if(amt===0){
            setErrorMsg("Enter amount for transactions !!")
            setError(true)
        } else if(reasonFor===""){
            setErrorMsg("Enter reason for transactions !!")
            setError(true)
        } else {
            setErrorMsg("")
            setError(false)
            const cashType="CASH IN"
            setLoadingAction("Cashing in...")
            addTransaction(cashType)
        }
    }
    const cashOut=()=>{
        if(amt===0){
            setErrorMsg("Enter amount for transactions !!")
            setError(true)
        } else if(reasonFor===""){
            setErrorMsg("Enter reason for transactions !!")
            setError(true)
        } else {
            const cashType="CASH OUT"            
            setErrorMsg("")
            setError(false)            
            setLoadingAction("Cashing out...")
            addTransaction(cashType)
        }
    }
    const addTransaction=(cashType)=>{        
        setLoading(true)
        setTimeout(async() => {
            let balance=0
            const res2 = await axios.get('https://leadsoft.academy/ganesh/APIS/getAccountBalance.php')
            const balanceRes = res2.data
            if(balanceRes.status==='success'){
                balance=Number(balanceRes.data)
            }
            let formData={}
            if(cashType==='CASH IN'){
                balance = Number(balance) + Number(amt)
                formData={
                    particular:reasonFor,
                    debitAmt:0,
                    creditAmt:amt,
                    balance:balance
                }
            } else if(cashType==='CASH OUT'){
                balance = Number(balance) - Number(amt)
                formData={
                    particular:reasonFor,
                    debitAmt:amt,
                    creditAmt:0,
                    balance:balance
                }
            }
            
            const res1 = await axios.post('https://leadsoft.academy/ganesh/APIS/addOpeningBalance.php',formData,{
                headers:{
                    'Content-Type':'application/json'
                }
            })
            setLoading(false)
            if(res1.data.status==='success'){
                toast.success('Trasanction recorded successfully !!')
                setAmt("0.00")
                setReason("")
                setLoadingAction("")
                if(amtRef.current){
                    amtRef.current.focus()
                }
            } else {
                toast.error("Something went wrong !!")
            }
        }, 200);
    }
    const amtRef = useRef(null)
    const getTransactions=async()=>{
        setLoadingAction("Getting transactions...")
        setLoading(true)
        const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getTransactions.php')
        setLoading(false)
        if(res.data.status === "success"){
            setTransactions(res.data.data)
        } else {
            setTransactions([])
        }
    }
    const getTransactionsDates=async()=>{
        setLoadingAction("Getting transactions...")
        setLoading(true)
        const res = await axios.get(`https://leadsoft.academy/ganesh/APIS/getTransactionsBetweenDate.php?dateFrom=${dateFrom}&dateTo=${dataTo}`)
        setLoading(false)
        if(res.data.status === "success"){
            setTransactions(res.data.data)
        } else {
            setTransactions([])
        }
    }
    const getTransactionsType=async(filter)=>{
        setLoadingAction("Getting transactions...")
        setLoading(true)
        const res = await axios.get(`https://leadsoft.academy/ganesh/APIS/getTransactionsBetweenDate.php?dateFrom=${dateFrom}&dateTo=${dataTo}&filter=${filter}`)
        setLoading(false)
        if(res.data.status === "success"){
            setTransactions(res.data.data)
        } else {
            setTransactions([])
        }
    }
    useLayoutEffect(()=>{
        if(amtRef.current){
            amtRef.current.focus()
        }
        getTransactions()
        getOpeningBalanceStatus()
        getLedgers()
    },[])
    const getOpeningBalanceStatus=async()=>{
        setLoadingAction("Setting balance status..")
        setLoading(true)
        setTimeout(async () => {
            const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getOpeningBalanceStatus.php')
            const info = res.data
            setLoading(false)
            if (info.status === "success") {
                setOpeningBalanceStatus(info.data)
            }
        }, 200);
    }
    const closeOpenBalance=()=>{
        setOpenBalance(false)
    }
    const closeLedgers=()=>{
        setLedgers(false)
    }
    const handlePrevious = () => {
        if (currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    
      const handleNext = () => {
        if (currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    return(
        <>
            {
                isLoading && 
                <Loading message={loadingAction}/>
            }
            {
                openBalance && 
                <OpeningBalance onClose={closeOpenBalance}/>
            }
            {
                ledgers &&
                <Ledgers onClose={closeLedgers}/>
            }
            <div className="child-container">
                <div className="action-panel">
                    <i className="fa-solid fa-sack-dollar"></i>
                    <h2>Manage Your Cash</h2>
                </div>
                <div className="form-container">
                    <div className="balance-container">
                        <div className="cash-flow">
                            <div className="form-field">
                                <label>Amount</label>
                                <div className="value-field">
                                    <img src={icon} alt='img' />
                                    <input type='number' ref={amtRef}  value={amt} 
                                        onChange={(e) => setAmt(e.target.value)} 
                                        onBlur={() => setAmt(parseFloat(amt).toFixed(2))}  
                                    />
                                </div>                
                            </div>
                            <div className="form-field">
                                <label>Reason</label>
                                <div className="value-field">
                                    <img src={reason} alt='img' />
                                    <select onChange={(e)=>setReason(e.target.value)} value={reasonFor}>
                                        <option defaultValue=''>SELECT LEDGER</option>
                                        {
                                            ledgerRecords.map((item,index)=>{
                                                return(
                                                    <option key={index} value={item.LedgerName}>{item.LedgerName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            { isError && <p>{errorMsg}</p> }
                            <div className="button-field">
                                <button onClick={cashIn} className='cash-in'> <i className="fa-solid fa-circle-plus"></i> Cash In </button>
                                <button onClick={cashOut} className='cash-out'> <i className="fa-solid fa-circle-minus"></i> Cash Out</button>
                            </div>
                        </div>
                        <div className="balance">
                            <div className="title-balance">
                                <img src={cashIcon} alt='img' />
                                <h2>Cash In Hand</h2>
                            </div>    
                            <div className="balance-field">
                                <p className='open debit' style={{background:"black"}} >Opening Balance</p>
                                <div className="value">
                                    <img src={icon} alt='img' />
                                    <p className='close debit-balance' style={{color:"black"}}>
                                        {getNumberInRupees(lastDayBalance)}
                                    </p>
                                </div>
                            </div>                       
                            <div className="balance-field">
                                <p className='open debit'>Debit</p>
                                <div className="value">
                                    <img src={icon} alt='img' />
                                    <p className='close debit-balance'>
                                        {getNumberInRupees(cashDebit)}
                                    </p>
                                </div>
                            </div>
                            <div className="balance-field">
                                <p className='open credit'>Credit</p>
                                <div className="value">
                                    <img src={icon} alt='img' />
                                    <p className='close credit-balance'>
                                        {getNumberInRupees(cashCredit)}
                                    </p>
                                </div>
                            </div>
                            <div className="balance-field">
                                <p className='open opening'>Balance</p>
                                <div className="value">
                                    <img src={icon} alt='img' />
                                    <p className='close opening-balance'>
                                        {getNumberInRupees(Number(lastDayBalance)+(cashCredit-cashDebit))}
                                    </p>
                                </div>
                            </div>
                            <div className="add-field">
                                {
                                    openingBalanceStatus==="NO" &&
                                    <button onClick={()=>setOpenBalance(true)} 
                                        className={openBalance ? 'active-btn1' : ''} 
                                        title='Add Opening Balance'> 
                                        <i className="fa-solid fa-plus"></i> Opening Balance
                                    </button>
                                }
                                <button onClick={()=>setLedgers(true)} className={ledgers ? 'active-btn1' : ''} title='Add Account Ledgers'> <i className="fa-solid fa-plus"></i> Account Ledgers</button>
                            </div>
                        </div>
                        
                    </div>
                    <div className="cash-manager">
                        <div className="filter-fields">
                            <div className="filter">
                                <h2>By Date</h2>
                                <div className="filter-field">
                                    <input type='date' value={dateFrom} onChange={handleFromChange}/> 
                                    <p>To</p>
                                    <input type='date' value={dataTo} onChange={handleToChange}/>
                                </div>
                            </div>
                            <div className="filter">
                                <h2>By Cash Type</h2>
                                <div className="filter-field">
                                    <select onChange={handleTypeChange} value={cashType || ''} >
                                        <option defaultValue='' selected>SELECT TYPE</option>
                                        <option value='CASH IN'>CASH IN</option>
                                        <option value='CASH OUT'>CASH OUT</option>
                                    </select>
                                </div>
                            </div>
                            <div className="filter">
                                <button>Show Transactions</button>
                            </div>
                        </div>
                        <table style={{boxShadow:"none"}}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Particular</th>
                                    <th>Debit (Rs.)</th>
                                    <th>Credit (Rs.)</th>
                                    <th>Balance (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentRecords.length > 0 ? (
                                        currentRecords.map((item,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{item.Date}</td>
                                                    <td>{item.Particular}</td>
                                                    <td>{item.DebitAmt}</td>
                                                    <td>{item.CreditAmt}</td>
                                                    <td>{item.Balance}</td>
                                                   
                                                </tr>
                                            )
                                        })
                                    ) :(
                                        <tr>
                                            <td colSpan="7">No Transactions found.</td>                                            
                                        </tr>
                                    )
                                }
                               
                                
                            </tbody>
                        </table>
                        <div className="pagination" style={{justifyContent:"space-between"}}>
                        {
                            totalPages > 1 &&
                            <>
                                <p style={{fontSize:"13px"}} ><span>Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong></span></p>
                                <div className="data123" style={{display:"flex",alignContent:"center",justifyContent:"end",gap:"5px"}}>
                                    <button title='Previous Page' onClick={()=>handlePrevious()} disabled={currentPage===1}><i className="fa-solid fa-circle-left"></i></button>
                                    <button title='Next Page' onClick={()=>handleNext()}disabled={currentPage===totalPages}><i className="fa-solid fa-circle-right"></i></button>
                                </div> 
                            </>
                        }  
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}
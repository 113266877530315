import { useForm } from 'react-hook-form';
import './style.css';
import { useState } from 'react';
import Loading from './Loading';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function FirstTime() {
    useGSAP(()=>{

        const tl = gsap.timeline()

        tl.from('.login-box',{
            opacity:0,
            y:-300,
            duration:0.35
        }).from('h2',{
            opacity:0,
            y:-30,
            duration:0.35
        }).from('form',{
            opacity:0,
            x:80,
            duration:0.45
        })
    },[])

    const { register, handleSubmit, watch, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [action, setAction] = useState("")
    const pwd = watch('pwd')
    const onLogin = (data) => {
        setAction('Creating...')
        setLoading(true)   
        setTimeout(async() => {
            setLoading(false)
            try{
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/createSuperUser.php',{
                    username:data.username,
                    password:data.pwd
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if(res.data.status === "success"){
                    toast.success("Super user created successfully !!")
                    window.location.reload();
                } else {
                    console.error(res.data.message)
                    toast.error("Something went wrong !!")
                }
            } catch(err){
                console.error(err)
                toast.error("Something went wrong")
            }
        }, 1200);
    }

    return (
        <>
            {isLoading && <Loading message={action} />}
            <div className="login-box">
                <div className="title">
                    <h2>Super User</h2>
                </div>
                <form onSubmit={handleSubmit(onLogin)}>
                    <div className="form-field">
                        <label>Username</label>
                        <div className="input-field">
                            <i className="fa-solid fa-user-graduate"></i>
                            <input type='email' {...register("username", {
                                required: "Username is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid username.",
                                },
                            })} />
                        </div>
                        {errors.username && <p className='error'>{errors.username.message}</p>}
                    </div>
                    <div className="form-field">
                        <label>Password</label>
                        <div className="input-field">
                            <i className="fa-solid fa-unlock-keyhole"></i>
                            <input type='password' {...register('pwd', {
                                required: 'Password is required.'
                            })}
                            />
                        </div>
                        {errors.pwd && <p className='error'>{errors.pwd.message}</p>}
                    </div>
                    <div className="form-field">
                        <label>Confirm Password</label>
                        <div className="input-field">
                            <i className="fa-solid fa-unlock-keyhole"></i>
                            <input type='password' {...register('cnf', {
                                required: 'Confirm Password is required.',
                                validate:(value)=>
                                    value===pwd || "Password does not match !!"
                            })}
                            />
                        </div>
                        {errors.cnf && <p className='error'>{errors.cnf.message}</p>}
                    </div>
                    <button type='submit'>CREATE SUPER USER</button>
                </form>
            </div>
        </>
    )
}
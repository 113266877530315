import './style.css';

export default function Loading({message}){
    return(
        <>
            <div className="loading-container">
                <div className="box">
                    <div className="circle">

                    </div>
                    <p className='msg'>{message}</p>
                </div>
            </div>
        </>
    )
}
import { useParams } from 'react-router-dom';
import './Admin.css';
import { useLayoutEffect, useState } from 'react';
import Loading from '../Loading';
import axios from 'axios';
import icon from '../../rupee-indian.png';

export default function AgentRecords(){

    const { agentName } = useParams()
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")
    const [agentObj,setAgentObj]=useState({})
    const [records,setRecords]=useState([])
    const recordsPerPage = 10;
    const [currentPage,setCurrentPage]=useState(1)
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(records.length / recordsPerPage);
    const [totalDebit,setTotalDebit]=useState(0)
    const [totalCredit,setTotalCredit]=useState(0)
    let today = new Date().toISOString().split('T')[0];

    const [dateFrom,setDateFrom]=useState(today)
    const [dateTo,setDateTo]=useState(today)

    const setValues=(data)=>{
        let debit =0, credit =0;
        data.forEach((element) => {
            debit += Number(element.DebitAmt)
            credit += Number(element.CreditAmt)
        });
        setTotalCredit(totalCredit=>credit)
        setTotalDebit(totalDebit=>debit)
    }
    const getNumberInRupees=(number)=>{
        let formattedNumber = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,  // Minimum of 2 decimal places
            maximumFractionDigits: 2,  // Maximum of 2 decimal places
        }).format(number);
        return formattedNumber
    }
    useLayoutEffect(() => {
        setValues(currentRecords);
    }, [currentRecords]);
    const getAgentDetails=()=>{
        setLoadingAction("Getting agents...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get(`https://leadsoft.academy/ganesh/APIS/getAgentDetails.php?aliasName=${agentName}`)
            setLoading(false)
            if(res.data.status==='success'){
                setAgentObj({...res.data.data})
                
            }
        }, 200);
    }
    const getAgentRecords=()=>{
        setLoadingAction("Getting records...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get(`https://leadsoft.academy/ganesh/APIS/getAgentRecords.php?aliasName=${agentName}`)
            setLoading(false)
            if(res.data.status==='success'){
                setRecords(res.data.data)                
            }
        }, 200);
    }
    useLayoutEffect(()=>{
        getAgentDetails()        
        getAgentRecords()
    },[])

    return(
        <>
            {
                isLoading &&
                <Loading message={loadingAction}/>
            }
            <div className="agent-container">
                <div className="agent-header">
                    <div className="value1">
                        <p>{agentObj && agentObj.AgentName}</p>
                        <p>{agentObj && agentObj.MobileNo}</p>
                    </div>
                    <div className="value2">
                        <h3>By date</h3>
                        <div className="filter-date">
                            <input type='date' value={dateFrom} onChange={(e)=>setDateFrom(e.target.value)} /> 
                            <p>To</p>
                            <input type='date' value={dateTo} onChange={(e)=>setDateTo(e.target.value)} />
                        </div>
                    </div>
                    <div className="value2">
                        <h3>By credit</h3>
                        <div className="filter-date">
                            <input type='checkbox'/>
                            <p>All Credit</p>
                        </div>
                    </div>
                </div>
                <table style={{width:"80%"}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Debit (Rs.)</th>
                            <th>Credit (Rs.)</th>
                            <th>Customer</th>
                            <th>Timestamp</th>
                            <th>Created By</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            records.length === 0 ? 
                            (
                                <tr>
                                    <td colSpan="8">No Records found !!</td>
                                </tr>
                            ) :
                            (
                                currentRecords.map((item,index)=>{
                                    return(
                                        <tr key={index} className={ Number(item.CreditAmt)===0 ? 'credit' : ( Number(item.DebitAmt) > Number(item.CreditAmt) ? 'debit' :'' ) }>
                                            <td>{item.Id}</td>
                                            <td>{item.Date}</td>
                                            <td>{item.DebitAmt}</td>
                                            <td>{item.CreditAmt}</td>
                                            <td>{item.CustomerName}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.created_by}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            )
                        }
                    </tbody>
                </table>
                <div className="pagination" style={{width:"80%", margin:"10px auto",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"blue",color:"white",fontWeight:"520"}}>Total Records :</p>
                        <p style={{padding:"4px",color:"blue",fontWeight:"bold"}}>
                            {records.length}
                        </p>
                        
                    </div>                
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"red",color:"white",fontWeight:"520"}}>Total Debit :</p>
                        <p style={{padding:"4px",color:"red",fontWeight:"bold"}}>{getNumberInRupees(totalDebit)}</p>
                    </div>
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"darkgreen",color:"white",fontWeight:"520"}}>Total Credit :</p>
                        <p style={{padding:"4px",color:"darkgreen",fontWeight:"bold"}}>
                            {getNumberInRupees(totalCredit)}</p>
                    </div>
                    <div className="data">
                        <p className='title' style={{padding:"4px",borderRadius:"3px",background:"transparent",color:"black",fontWeight:"520"}}>Balance :</p>
                        <img src={icon}/>
                        <p style={{padding:"4px",color:"black",fontWeight:"bold"}}>
                            {getNumberInRupees((totalDebit-totalCredit))}</p>
                    </div>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"end",gap:"7px"}}>
                    {
                        Array.from({ length: totalPages }, (_, index) => (
                        <button className={currentPage===index+1 ? 'selected-page':""} key={index + 1} onClick={() => setCurrentPage(index + 1)}>
                            {index + 1}
                        </button>
                        ))
                    }
                    </div>
                </div>
            </div>
        </>
    )
}
import { useForm } from 'react-hook-form';
import './style.css';
import { useEffect, useState } from 'react';
import Loading from './Loading';
import axios from 'axios';
import FirstTime from './FirstTime';
import toast from 'react-hot-toast';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';


export default function Login() {

    const { register, handleSubmit, formState: { errors },setFocus,resetField } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [action, setAction] = useState("")
    const [firstTimeStatus, setFirstTimeStatus] = useState("")    
    const navigate = useNavigate()

    useGSAP(()=>{

        const tl = gsap.timeline()

        tl.from('.login-box',{
            opacity:0,
            y:-300,
            duration:0.35
        }).from('h2',{
            opacity:0,
            y:-30,
            duration:0.35
        }).from('form',{
            opacity:0,
            x:80,
            duration:0.45
        })
    },[firstTimeStatus])

    useEffect(() => {

        const user = localStorage.getItem('user')
        if(user){
            navigate('/dashboard')
        }
        setAction('Checking...')
        setLoading(true)
        setTimeout(async () => {
            const res = await axios.get('https://leadsoft.academy/ganesh/APIS/getFirstTimeStatus.php')
            const info = res.data
            setLoading(false)
            if (info.status === "success") {
                setFirstTimeStatus(info.data)
            }
        }, 200);
    }, [])

    const onLogin = (data) => {
        setAction('Checking...')
        setLoading(true)
        setTimeout(async() => {
            setLoading(false)
            try{
                const res = await axios.post('https://leadsoft.academy/ganesh/APIS/login.php',{
                    username:data.username,
                    password:data.pwd,
                    userType:data.userType
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                let jsonObject={
                    username:data.username,
                    userType:data.userType
                }
                if(res.data.status === "success"){
                    toast.success("Login successfully !!")
                    localStorage.setItem('user',JSON.stringify(jsonObject))
                    if(data.userType==="Admin"){
                        navigate('/dashboard')
                    } else if(data.userType==="User"){
                        navigate('/user-dashboard')
                    }
                } else {       
                    if(res.data.status==="error" && res.data.message==="Username not found"){
                        toast.error("Username not found !!")
                        resetField('username')
                        resetField('pwd')
                        resetField('userType')
                        setFocus("username")

                    } else {
                        toast.error("Invalid password !!")                        
                        resetField('pwd')
                        setFocus("pwd")
                        resetField('userType')
                    }
                    
                }
            } catch(err){
                console.error(err)
                toast.error("Something went wrong")
            }
        }, 1200);
    }

    return (
        <>
        
            {isLoading && <Loading message={action} />}
            
            
            <div className="container">
                {
                    firstTimeStatus==="YES" &&
                    <div className="login-box">
                        <div className="title">
                            <h2>Login</h2>
                        </div>
                        <form onSubmit={handleSubmit(onLogin)}>
                            <div className="form-field">
                                <label>Username</label>
                                <div className="input-field">
                                    <i className="fa-solid fa-user-graduate"></i>
                                    <input type='text'  {...register("username", {
                                        required: "Username is required."
                                    })} />
                                </div>
                                { errors.username && <p className='error'>{errors.username.message}</p> }
                            </div>
                            <div className="form-field">
                                <label>Password</label>
                                <div className="input-field">                                
                                    <i className="fa-solid fa-unlock-keyhole"></i>
                                    <input type='password' {...register('pwd',{
                                        required:'Password is required.'
                                    })}    
                                    />
                                </div>
                                { errors.pwd && <p className='error'>{errors.pwd.message}</p> }
                            </div>
                            <div className="form-field">
                                <label>User Type</label>
                                <div className="input-field">                                
                                    <i className="fa-solid fa-user-tag"></i>
                                    <select {...register('userType',{
                                        required:"User type is required."
                                    })}>
                                        <option value=''>SELECT</option>
                                        <option value='Admin'>Admin</option>
                                        <option value='User'>User</option>
                                    </select>
                                    
                                </div>
                                { errors.userType && <p className='error'>{errors.userType.message}</p> }
                            </div>
                            <button type='submit'>Login</button>
                        </form>
                    </div>
                }
                {
                    firstTimeStatus==="NO" &&
                    <FirstTime/>
                }
            </div>
                         
            <div className="footer-container">
                <p className='copy-right'>&copy; {new Date().getFullYear()} LeadSoft IT Solutions. All Rights Reversed </p>
            </div>
            
        </>
    )
}
import { useForm } from 'react-hook-form';
import './Admin.css';
import { useState } from 'react';
import Loading from '../Loading';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function OpeningBalance( {onClose} ){
    
    const {register,handleSubmit,formState:{errors},setValue}=useForm()
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")


    const addBalance=(data)=>{
        setLoadingAction("Setting opening balance...")
        setLoading(true)
        const payLoad= {
            particular:"Opening Balance",
            debitAmt:0,
            creditAmt:data.balance,
            balance:data.balance
        }
        setTimeout(async() => {
            const res = await axios.post('https://leadsoft.academy/ganesh/APIS/addOpeningBalance.php',payLoad,{
                headers:{
                    'Content-Type':'application/json'
                }
            })
            const info = res.data
            setLoading(false)
            if(info.status==='success'){
                toast.success('Opening balance added successfully !!')
                onClose()
            } else {
                toast.error('Something went wrong !!')
            }
        }, 200);
    }
    const handleDebitChange = (e) => {
        let value = parseFloat(e.target.value);
        console.log(value)
        if (!isNaN(value)) {
            setValue('balance', value.toFixed(2));
        }
    };
    return(
        <>
            {
                isLoading &&
                <Loading message={loadingAction}/>
            }
            <div className="page-container">
                <form onSubmit={handleSubmit(addBalance)}>
                    <div className="title-form">
                        <h2>Add opening balance</h2>
                    </div>
                    <div className="input-field">
                        <label>Balance</label>
                        <input type='number'  {...register('balance',{
                                required:"Opening balance is required."})} 
                                onBlur={handleDebitChange}
                                    
                        />    
                    </div>
                    { errors.balance && <p className='error-here'>{errors.balance.message}</p> }
                    <div className="button-field">
                        <button type='submit'>Add Balance</button>
                        <button onClick={onClose} type='reset'>Close</button>
                    </div>
                </form>
            </div>
        </>
    )
}